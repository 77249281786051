<template>
    <a-carousel arrows autoplay style="width: 7rem;margin-left: -1.25rem;">
        <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow"style="left: 10px;zIndex: 1">
            <a-icon type="left-circle" />
        </div>
        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right-circle" />
        </div>
        <div style="height:6.5rem;;color: #fff;" v-for="(item, index) in liveList" :key="index">
            <img slot="cover" alt="example" width="600" height="245" style="max-width: 100%;"  :src="item.liveImg" />
            <p class="text-left">{{ item.liveStartTime | setLiveStartTime}}</p><br>
            <p class="text-left">直播主题：{{item.liveName}}</p><br>
            <p class="text-left">主讲老师：{{item.anchorTeacherName}}</p><br>
            <p class="text-left">{{item.liveState | setStateText}}</p>
        </div>
    </a-carousel>
</template>

<script>
import api from '@/api'

export default {
    name: 'week-live',
    data() {
        return {
            liveList: []
        }
    },
    filters: {
        setLiveStartTime(val) {
            let moment = require("moment");
            let today = moment();
            let yesterday = moment().subtract(1, 'day');
            let nextday = moment().add(1, 'day');

            if(moment(val).isSame(today, 'day')) {
                return "今日 " +  moment(val).format('HH:mm')
            } else if(moment(val).isSame(yesterday, 'day')) {
                return "昨日 " +  moment(val).format('HH:mm')
            } else if(moment(val).isSame(nextday, 'day')) {
                return "明日 " +  moment(val).format('HH:mm')
            } else {
                return moment(val).format('MM-DD HH:mm')
            }
        },
        setStateText(val) {
            let text = '即将开始'
            switch (val) {
                case '1':
                    text = '直播中'
                    break
                case '3':
                    text = '已结束'
                    break
                case '2':
                    text = '未开始'
                    break
            }
            return text
        }
    },
    mounted() {
        this.getWeekLiveList()
    },
    methods: {
        async getWeekLiveList() {
            let res = await api.bigdata.getWeekLiveList({organizationId: localStorage.getItem('organizationId')})
            if (res.code === 200) {
                this.liveList = res.data;
            }
        }
    }
}
</script>

<style scoped>
    /* For demo */
    .ant-carousel >>> .slick-slide {
        text-align: center;
        height: 100%;
        line-height: 100%;
        overflow: hidden;
        background: rgba(0, 153, 145, 0.2);
    }
    .ant-carousel >>> .custom-slick-arrow {
        width: 25px;
        height: 25px;
        font-size: 25px;
        color: #fff;
        background-color: rgba(31, 45, 61, 0.11);
        opacity: 0.3;
    }
    .ant-carousel >>> .custom-slick-arrow:before {
        display: none;
    }
    .ant-carousel >>> .custom-slick-arrow:hover {
        opacity: 0.5;
    }
    .ant-carousel >>> .slick-slide h3 {
        color: #fff;
    }
    .text-left {
        text-align: left;
        margin-left: 1.5rem;
        margin-top: 0.2rem;
        font-size: 16px;
    }
</style>