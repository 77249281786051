<template>
    <div class="brand-container">
        <div class="wrap">
            <header>
                <div class="weather" />
                <h2>学情监测</h2>
                <div class="showTime" />
            </header>

            <section class="mainbox">
                <div class="item left">
                    <div class="panel">
                        <h2>近七日各专业学习次数</h2>
                        <major-learn-num />
                        <div class="panel-footer"></div>
                    </div>
                    <div class="panel-left2">
                        <h2>近七日日均学习次数趋势</h2>
                        <major-learn-num-trend />
                        <div class="panel-footer"></div>
                    </div>
                </div>

                <div class="item center">
                    <div class="resume">
                        <div class="resume-hd">
                            <ul>
                                <li>
                                    <countTo :startVal='startVal' :endVal='80854' :duration='6000' separator=""></countTo>
                                </li>
                                <li>
                                    <countTo :startVal='startVal' :endVal='courseDiscussNum' :duration='6000' separator=""></countTo>
                                </li>
                            </ul>
                        </div>
                        <div class="resume-bd">
                            <ul>
                                <li>近一月学习行为总数</li>
                                <li>近一月课程讨论总数</li>
                            </ul>
                        </div>
                    </div>

                    <div class="panel-live">
                        <h2>本周直播</h2>
                        <week-live />
                        <div class="panel-footer"></div>
                    </div>
                </div>

                <div class="item right">
                    <div class="panel">
                        <h2 style="height: 0.1rem"></h2>
                        <div class="resume-right">
                            <div class="resume-hd">
                                <h2>活跃学生TOP5</h2>
                                <ul >
                                    <span style="float:left;margin-right:0.4rem;color: white"  v-for="(item, index) in activeList" :key="index">
                                        <a-avatar icon="user" :size="52" :src="item.studIdImg" /><p class="avatar-text">{{ item.realName }}</p>
                                    </span>
                                </ul>
                            </div>

                            <div class="resume-bd">
                                <ul />
                            </div>


                            <div class="resume-hd">
                                <h2>活跃教师TOP5</h2>
                                <ul >
                                    <span style="float:left;margin-right:0.4rem;color: white"  v-for="(item, index) in teacherList" :key="index">
                                        <a-avatar icon="user" :size="52" :src="item.url" /><p class="avatar-text">{{ item.name }}</p>
                                    </span>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div class="panel-dynamic">
                        <h2>实时动态</h2>
                        <!--<time-dynamic />-->
                        <div class="panel-footer"></div>
                    </div>
                </div>
            </section>

        </div>

    </div>
</template>

<script>
import '@/assets/js/flexible'
import countTo from 'vue-count-to'
import MajorLearnNum from "./c/MajorLearnNum.vue";
import MajorLearnNumTrend from "./c/MajorLearnNumTrend.vue";
import WeekLive from "./c/WeekLive.vue";
import TimeDynamic from "../today-dynamic/c/TimeDynamic.vue";
import api from "@/api"

export default {
    components: {
        TimeDynamic,
        WeekLive,
        MajorLearnNumTrend,
        countTo,
        MajorLearnNum
    },
    name: 'study-monitor',
    data() {
        return {
            startVal: 0,
            courseDiscussNum: 0,
            activeList: [],
            teacherList: [
                {name: "郭少君",url: require("@/assets/img/bigdata-screen/guosj.jpg") },
                {name: "李俞",url: require("@/assets/img/bigdata-screen/liy.jpg") },
                {name: "刘飞燕",url: require("@/assets/img/bigdata-screen/liufy.jpg") },
                {name: "苏咏研",url: require("@/assets/img/bigdata-screen/suyy.jpg") },
                {name: "严嘉敏",url: require("@/assets/img/bigdata-screen/yanjm.jpg") },
            ],
        }
    },
    created() {
        this.initOrganizationByDomain();
    },
    mounted() {
        this.getStudyActive();
        this.getStudyCount();
    },
    methods: {
        async getStudyActive() {
            let res = await api.bigdata.getStudyActive({organizationId: localStorage.getItem('organizationId')})
            if (res.code === 200) {
                this.activeList = res.data
            }
        },
        async getStudyCount() {
            let res = await api.bigdata.getStudyCount({organizationId: localStorage.getItem('organizationId')})
            if (res.code === 200) {
                this.courseDiscussNum = res.data.courseDiscussNum;
            }
        },
        async initOrganizationByDomain() {
            const organizationId = localStorage.getItem('organizationId')
            if (!organizationId) {
                const domain =  window.location.origin || 100000
                let res = await api.protal.getOrganizationByDomain({ domain })
                if (res.code === 200 && res.data) {
                    localStorage.setItem('organizationId', res.data.organizationId)
                    location.reload();
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../assets/css/bigdata-screen/today-dynamic.scss";
    .avatar-text {
        margin: 0.1rem 0.1rem 0.1rem 0.1rem;
    }
</style>