<template>
    <div class="business-container">
        <div class="chart" id="chart_left2"></div>
    </div>
</template>

<script>
export default {
    name: 'major-learn-num-trend',
    data() {
        return {}
    },
    mounted() {
        this.getEchartLeft2();
    },
    methods: {
        getEchartLeft2() {
            let myChart = echarts.init(document.getElementById('chart_left2'));
            let data = {
                xData: ['7.1', '7.2', '7.3', '7.4', '7.5', '7.6','7.7'],
                yData: ['1000', '1200', '1000', '1500', '2400', '3000','2600']
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0,
                                    color: 'rgba(255,255,255,0)' // 0% 处的颜色
                                }, {
                                    offset: 0.5,
                                    color: 'rgba(255,255,255,1)' // 100% 处的颜色
                                }, {
                                    offset: 1,
                                    color: 'rgba(255,255,255,0)' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
                    },
                    formatter: (value) => {
                        return value[0].name + '学习次数：<br>' + value[0].value + '次';
                    }
                },
                grid: {
                    top: '5%',
                        left: '2%',
                        right: '2%',
                        bottom: '0%',
                        containLabel: true
                },
                xAxis: [{
                    type: 'category',
                    boundaryGap: false,  //xAxis中的boundaryGap属性，设置为false代表是零刻度开始，设置为true代表离零刻度间隔一段距离
                    axisLine: { // 坐标轴 轴线
                        show: true,
                        lineStyle: {
                            color: '#092b5d'
                        },
                    },
                    axisLabel: { //坐标轴 刻度标签的相关设置
                        textStyle: {
                            color: '#24c4ff',
                            margin: 15,
                        }
                    },
                    data: data.xData
                }],
                yAxis: [{
                    min: 0,
                    max: 4000,
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: '#092b5d'
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#092b5d"
                        }
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#24c4ff',
                        }
                    },
                    axisTick: {
                        show: false,
                    },
                }],
                series: [{
                    name: '学习次数',
                    type: 'line', //折线图
                    symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
                    showAllSymbol: true,
                    symbolSize: 8,
                    lineStyle: {
                        normal: {
                            color: '#7c80f4', // 线条颜色
                        },
                        borderColor: 'rgba(0,0,0,.4)',
                    },
                    itemStyle: {
                        color: "rgba(14,30,73,1)",
                        borderColor: "#646ace",
                        borderWidth: 2
                    },
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            formatter: [
                                ' {a|{c}}',
                            ].join(','),
                            rich: {
                                a: {
                                    color: '#fff',
                                    align: 'center',
                                },
                            }
                        }
                    },
                    smooth: true,
                    areaStyle: { //区域填充样式
                        normal: {
                            //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: "rgba(124, 128, 244,.3)"
                            },{
                                offset: 1,
                                color: "rgba(124, 128, 244, 0)"
                            }], false),
                            shadowColor: 'rgba(53,142,215, 0.9)', //阴影颜色
                            shadowBlur: 20 //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                        }
                    },
                    data: data.yData
                }]
            }
            myChart.setOption(option, true);
            window.addEventListener('resize', () => {
                myChart.resize();
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .business-container {
        .chart {
            height: 4rem;
            margin-top: 0.5rem;
        }
    }
</style>